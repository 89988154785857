"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var blend = require("mixpanel-browser");
var environment_1 = require("../../../environments/environment");
var logger_service_1 = require("src/app/core/logger.service");
var i0 = require("@angular/core");
var i1 = require("../../core/logger.service");
var UnauthBlendService = /** @class */ (function () {
    function UnauthBlendService(log) {
        this.log = log;
        this.blend = blend.init(environment_1.environment.unauthBlendToken, tslib_1.__assign({ 'persistence': 'localStorage', 'api_host': environment_1.environment.blendHost }, (!environment_1.environment.production && { 'debug': true })), 'Sync — unauthenticated');
    }
    UnauthBlendService.prototype.linkURLSanitizer = function (url) {
        try {
            // Parse the URL
            var parsedUrl = new URL(url);
            // Split the pathname into parts
            var pathParts = parsedUrl.pathname.split('/').filter(function (part) { return part !== ''; });
            var rootPath = (pathParts.length) ? pathParts[0] : '';
            switch (rootPath) {
                case 'dl': // e.g., /dl/aaaabbbb0/xxx-xxx-xx-xxx
                    parsedUrl.pathname = '/' + pathParts.slice(0, 2).join('/');
                    break;
            }
            return parsedUrl.href;
        }
        catch (error) {
            this.log.error('Error processing URL: ' + error.message);
            return url; // Return the original URL if there's an error
        }
    };
    UnauthBlendService.prototype.isUrlHistoryClean = function () {
        // Parse the referrer and current URL
        var referrer = new URL(document.referrer, window.location.origin);
        var currentUrl = new URL(window.location.href);
        // Check if the referrer path starts with '/dl/'
        var referrerHasDl = referrer.pathname.startsWith('/dl/');
        // Check if the current URL path starts with '/dl/'
        var currentHasDl = currentUrl.pathname.startsWith('/dl/');
        return !referrerHasDl && !currentHasDl;
    };
    UnauthBlendService.prototype.getDefaultProperties = function () {
        if (!this.isUrlHistoryClean()) {
            var defaultProperties_1 = {
                'current_page_title': 'Sync.com — link',
                'current_url_path': '-',
                '$current_url': this.linkURLSanitizer(window.location.href),
                'platform': 'CP',
                '$referrer': this.linkURLSanitizer(document.referrer),
            };
            return defaultProperties_1;
        }
        var defaultProperties = {
            'current_url_path': window.location.pathname,
            'platform': 'CP'
        };
        return defaultProperties;
    };
    UnauthBlendService.prototype.trackPageview = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.blend.track_pageview(this.getDefaultProperties());
                return [2 /*return*/];
            });
        });
    };
    UnauthBlendService.ngInjectableDef = i0.defineInjectable({ factory: function UnauthBlendService_Factory() { return new UnauthBlendService(i0.inject(i1.LoggerService)); }, token: UnauthBlendService, providedIn: "root" });
    return UnauthBlendService;
}());
exports.UnauthBlendService = UnauthBlendService;
