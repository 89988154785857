"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var version = window.SYNCCFG.version;
var upgradePromoBannerUrl = window.SYNCCFG.upgradePromoBannerUrl;
var viewhost, previewhost1, previewhost2, previewhost3, webhost, linkhost, publinkdatahost, publinkdatahostpro, currenthost, wopihost, logohost, commenthost, multiadminhost, cphost, featureHost, eventreaderhost;
var cookiedomain = window.location.host.indexOf('live-cp-') > -1
    ? window.location.host
    : window.SYNCCFG.cookiehost;
webhost = window.SYNCCFG.webhost;
linkhost =
    window.location.host.indexOf('live-cp-') > -1 ||
        window.location.host.indexOf('cp-lb') > -1
        ? "https://" + window.location.host + "/"
        : window.SYNCCFG.linkhost;
publinkdatahost = window.SYNCCFG.publinkdatahost;
publinkdatahostpro = window.SYNCCFG.publinkdatahostpro;
previewhost1 = window.SYNCCFG.previewhost1;
previewhost2 = window.SYNCCFG.previewhost2;
previewhost3 = window.SYNCCFG.previewhost3;
viewhost = window.SYNCCFG.viewhost;
wopihost = window.SYNCCFG.wopihost;
logohost = window.SYNCCFG.logohost;
commenthost = window.SYNCCFG.commenthost;
multiadminhost = window.SYNCCFG.multiadminhost;
featureHost = window.SYNCCFG.featureHost;
eventreaderhost = window.SYNCCFG.eventreaderhost;
cphost =
    window.location.host.indexOf('live-cp-') > -1
        ? "https://" + window.location.host
        : window.SYNCCFG.cphost;
currenthost = "https://" + window.location.host;
exports.environment = {
    production: true,
    version: version,
    jslibpath: '/workers/',
    apihosts: ['/api/'],
    wopihost: wopihost,
    currenthost: currenthost,
    cookiedomain: cookiedomain,
    cookiesecure: true,
    // host names
    linkhost: linkhost,
    publinkdatahost: publinkdatahost,
    publinkdatahostpro: publinkdatahostpro,
    previewhost1: previewhost1,
    previewhost2: previewhost2,
    previewhost3: previewhost3,
    viewhost: viewhost,
    webhost: webhost,
    ordershost: window.SYNCCFG.ordershost,
    imageconversionhost: 'https://imgconv.sync.com',
    logohost: logohost,
    commenthost: commenthost,
    multiadminhost: multiadminhost,
    cphost: cphost,
    extapihost: window.SYNCCFG.extapihost,
    teamPlusIds: [],
    blendToken: window.SYNCCFG.blendToken,
    unauthBlendToken: window.SYNCCFG.unauthBlendToken,
    blendHost: window.SYNCCFG.blendHost,
    featureHost: featureHost,
    upgradePromoBannerUrl: upgradePromoBannerUrl,
    syncCpHost: window.SYNCCFG.syncCpHost,
    eventreaderhost: eventreaderhost,
};
exports.extModules = [];
