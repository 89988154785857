"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var api_service_1 = require("../../core/api.service");
var base64_service_1 = require("../../core/base64.service");
var browser_support_service_1 = require("../../core/browser-support.service");
var sync_crypt_service_1 = require("../../core/crypt/sync-crypt.service");
var logger_service_1 = require("../../core/logger.service");
var notifications_service_1 = require("../../core/notifications.service");
var sync_cookie_service_1 = require("../../core/sync-cookie.service");
var url_service_1 = require("../../core/url.service");
var user_service_1 = require("../../core/user.service");
var validate_service_1 = require("../../core/validate.service");
var file_list_service_1 = require("../../file-list/services/file-list.service");
var link_file_list_service_1 = require("../../link-consume/services/link-file-list.service");
var func_1 = require("../../shared/func");
var models_1 = require("../../shared/models");
var broadcast_service_1 = require("../../shared/services/broadcast.service");
var transfer_model_1 = require("../transfer.model");
var download_service_1 = require("./download.service");
var transfer_item_service_1 = require("./transfer-item.service");
var file_uploader_1 = require("../file-uploader");
var i0 = require("@angular/core");
var i1 = require("../../core/notifications.service");
var i2 = require("./download.service");
var i3 = require("../../file-list/services/file-list.service");
var i4 = require("../../core/browser-support.service");
var i5 = require("../../core/sync-cookie.service");
var i6 = require("./transfer-item.service");
var i7 = require("../../core/validate.service");
var i8 = require("../../core/base64.service");
var i9 = require("../../core/logger.service");
var i10 = require("../../core/crypt/sync-crypt.service");
var i11 = require("../../core/url.service");
var i12 = require("../../core/user.service");
var i13 = require("../../core/api.service");
var i14 = require("../../link-consume/services/link-file-list.service");
var i15 = require("../../shared/services/broadcast.service");
var BuildTransferItemService = /** @class */ (function () {
    function BuildTransferItemService(notificationsService, downloadService, fileListService, browserSupportService, syncCookieService, transferItemService, validateService, base64Service, loggerService, syncCryptService, urlService, userService, apiService, linkFileListService, broadcastService, injector) {
        var _this = this;
        this.notificationsService = notificationsService;
        this.downloadService = downloadService;
        this.fileListService = fileListService;
        this.browserSupportService = browserSupportService;
        this.syncCookieService = syncCookieService;
        this.transferItemService = transferItemService;
        this.validateService = validateService;
        this.base64Service = base64Service;
        this.loggerService = loggerService;
        this.syncCryptService = syncCryptService;
        this.urlService = urlService;
        this.userService = userService;
        this.apiService = apiService;
        this.linkFileListService = linkFileListService;
        this.broadcastService = broadcastService;
        this.injector = injector;
        this.ACT_DOWNLOAD = 1;
        this.ACT_PREVIEW = 2;
        this.ACT_STREAM_PDF = 3;
        this.ACT_STREAM_DOC = 4;
        this.MAX_ACTIVE_UPLOADS = 3;
        this.MAX_ACTIVE_DOWNLOADS = 1;
        this.view = {
            download: {
                queue: [],
                queueIdx: 0,
                isProcessing: false,
                activeItemCount: 0,
            },
            upload: {
                queue: [],
                queueIdx: 0,
                isProcessing: false,
                activeItemCount: 0
            },
            single: undefined,
            isUpload: false,
            isDownload: false,
            enabled: this.browserSupportService.testXhr2(),
            pending: 0,
            cannotViewSave: false,
            transferLabel: 'Upload',
        };
        this.fileListService.getCwdSubscription().subscribe(function (data) {
            if (data) {
                _this.cwd = data;
            }
        });
        this.linkFileListService.getCwdSubscription().subscribe(function (data) {
            if (data) {
                _this.linkCwd = data;
            }
        });
        window.onbeforeunload = function (evt) {
            var message = 'There is unfinished upload/download pending.';
            if (typeof evt == 'undefined') {
                evt = window.event;
            }
            if (_this.view.download.isProcessing || _this.view.upload.isProcessing) {
                if (evt) {
                    evt.returnValue = message;
                }
                return message;
            }
        };
    }
    BuildTransferItemService.prototype.addToQueue = function (results, queueType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var view;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                view = this.view[queueType];
                if (results.length) {
                    view.queue = view.queue.concat(results);
                    view.queue.sort(function (a, b) {
                        return func_1.compare(a._initTime, b._initTime, true);
                    });
                }
                setTimeout(function () { return _this.runQueue(queueType); }, 0);
                return [2 /*return*/, true];
            });
        });
    };
    BuildTransferItemService.prototype.shouldProcessQueue = function (queueType) {
        var view = this.view[queueType];
        var maxActiveItems = queueType === transfer_model_1.TransferQueueType.UPLOAD ? this.MAX_ACTIVE_UPLOADS : this.MAX_ACTIVE_DOWNLOADS;
        return view.activeItemCount < maxActiveItems;
    };
    BuildTransferItemService.prototype.shouldStopQueue = function (queueType) {
        var view = this.view[queueType];
        return view.activeItemCount === 0;
    };
    BuildTransferItemService.prototype.runQueue = function (queueType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var view, next, tItem_1, fileUploader_1, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        view = this.view[queueType];
                        if (!view.queue.length) return [3 /*break*/, 7];
                        next = this.getLatestItem(view.queue);
                        this.view.pending = next.pending;
                        if (!(next.idx > -1 && this.shouldProcessQueue(queueType))) return [3 /*break*/, 6];
                        this.notificationsService.stopNotificationLoop();
                        view.isProcessing = true;
                        view.queueIdx = next.idx;
                        tItem_1 = view.queue[next.idx];
                        if (!tItem_1) return [3 /*break*/, 5];
                        this.view.transferLabel = 'Transferring';
                        if (!(tItem_1.type == this.transferItemService.TYPE_UPLOAD)) return [3 /*break*/, 1];
                        this.view.isUpload = true;
                        fileUploader_1 = new file_uploader_1.FileUploader(this.injector);
                        fileUploader_1.uploadItem(tItem_1)
                            .then(function () {
                            fileUploader_1 = null;
                            _this.runQueue(queueType);
                        })
                            .catch(function (error) {
                            _this.handleError(error, tItem_1);
                            fileUploader_1 = null;
                            _this.runQueue(queueType);
                        });
                        return [3 /*break*/, 4];
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.view.isDownload = true;
                        return [4 /*yield*/, this.downloadService.downloadItem(tItem_1)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.handleError(error_1, tItem_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.runQueue(queueType);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        if (this.shouldStopQueue(queueType)) {
                            this.notificationsService.startNotificationLoop();
                            view.isProcessing = false;
                            if (queueType === transfer_model_1.TransferQueueType.UPLOAD) {
                                this.view.isUpload = false;
                            }
                            else if (queueType === transfer_model_1.TransferQueueType.DOWNLOAD) {
                                this.view.isDownload = false;
                            }
                            if (!this.view.download.isProcessing && !this.view.upload.isProcessing) {
                                this.view.transferLabel = 'Upload';
                            }
                            if (this.userService.isAuthenticated()) {
                                this.broadcastService.broadcast('event:file-list.reload');
                            }
                            this.linkFileListService.reload();
                        }
                        _a.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    BuildTransferItemService.prototype.handleError = function (data, tItem) {
        if (typeof data === 'object' &&
            data.errors &&
            data.errors.length) {
            tItem.status =
                data.errors[0].error_code ||
                    transfer_model_1.TransferStatus.STATUS_ERROR;
            this.loggerService.error(data.errors[0].error_msg);
        }
        else if (data.errcode) {
            tItem.status = data.errcode;
        }
        else if (data.code) {
            tItem.status = data.code;
        }
        else {
            tItem.status = transfer_model_1.TransferStatus.STATUS_ERROR;
        }
        this.loggerService.error([
            'Transfer encountered an error',
            ' type: ',
            tItem.type,
            ' status: ',
            tItem.status,
            ' size: ',
            tItem.filesize,
        ].join(''));
    };
    BuildTransferItemService.prototype.getLatestItem = function (items) {
        var idx = -1, pendingBytes = 0, pending = 0, activeUploadsCount = 0, activeDownloadsCount = 0;
        for (var i = 0, len = items.length; i < len; i++) {
            if (items[i].status == transfer_model_1.TransferStatus.STATUS_WAITING) {
                if (idx === -1) {
                    idx = i;
                }
                pending++;
                pendingBytes += items[i].filesize;
            }
            if (items[i].type === this.transferItemService.TYPE_UPLOAD && transfer_model_1.ValidUploadStatus.includes(items[i].status)) {
                activeUploadsCount++;
            }
            if (items[i].type === this.transferItemService.TYPE_DOWNLOAD && transfer_model_1.ValidDownloadStatus.includes(items[i].status)) {
                activeDownloadsCount++;
            }
        }
        this.view.upload.activeItemCount = activeUploadsCount;
        this.view.download.activeItemCount = activeDownloadsCount;
        return {
            pending: pending,
            pendingBytes: pendingBytes,
            idx: idx,
        };
    };
    BuildTransferItemService.prototype.getCompatUrl = function (file, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var keyData, tItem, url;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFileKeys([file])];
                    case 1:
                        keyData = _a.sent();
                        return [4 /*yield*/, this.mkDownloadItem(file, keyData)];
                    case 2:
                        tItem = _a.sent();
                        return [4 /*yield*/, this.mkCompatUrl(tItem, action)];
                    case 3:
                        url = _a.sent();
                        if (action == this.ACT_DOWNLOAD) {
                            this.broadcastService.broadcast('sync.track.blend', {
                                eventName: models_1.BlendEvent.DOWNLOAD_FILE,
                                parameters: {
                                    isCompat: 'Yes',
                                    fileSize: file.filesize,
                                    mimeType: file.mime_type
                                }
                            });
                        }
                        return [2 /*return*/, url];
                }
            });
        });
    };
    /**
     * Gets the data and share keys for an array of files
     * @param files
     */
    BuildTransferItemService.prototype.getFileKeys = function (files) {
        var needKeys = [];
        var isLink = false;
        for (var i = 0, len = files.length; i < len; i++) {
            if (files[i].share_id && files[i].sync_id && files[i].blob_id) {
                var ext = files[i].name
                    .substr(files[i].name.lastIndexOf('.') + 1)
                    .toLowerCase();
                needKeys.push({
                    share_id: files[i].share_id,
                    blob_id: files[i].blob_id,
                    sync_id: files[i].sync_id,
                    ext: this.base64Service.encode(ext),
                    link_cachekey: files[i].linkID,
                    size: files[i].size,
                    user_id: this.userService.get('id'),
                });
            }
            if (files[i].linkID && isLink === false) {
                isLink = true;
            }
        }
        var pubkey = !isLink ? this.userService.get('pubkey_id') : undefined;
        return this.apiService.execute('pathdata', {
            pathitems: needKeys,
            pubkey: pubkey
        });
    };
    BuildTransferItemService.prototype.mkDownloadItem = function (file, keyData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var curDataKey, enc_data_key, enc_share_key, previewtoken, result;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // TODO: This is a hack when we don't have the keydata in the Webpanel
                        // TODO: Please sign off on this Richard.
                        if (keyData) {
                            curDataKey = keyData.datakeys[file.sync_id];
                            enc_data_key = curDataKey.enc_data_key;
                            enc_share_key = keyData.sharekeys[curDataKey.share_key_id];
                            previewtoken = keyData.previewtoken || undefined;
                        }
                        else {
                            curDataKey = {
                                share_key_id: undefined,
                                share_id: undefined,
                                share_sequence: undefined,
                            };
                        }
                        return [4 /*yield*/, this.transferItemService.get({
                                context: file.context,
                                type: this.transferItemService.TYPE_DOWNLOAD,
                                blob_id: file.blob_id,
                                blobtype: file.blobtype || 'btFILE',
                                filename: file.name,
                                name: file.name,
                                filesize: file.size,
                                filetype: file.filetype,
                                mimetype: file.filetype.mimetype,
                                filedate: file.date,
                                cachekey: file.cachekey,
                                link_is_pro: file.link_is_pro || 0,
                                link_owner_id: file.link_owner_id || 0,
                                linkID: file.linkID || undefined,
                                linkpasswordlock: file.linkpasswordlock || undefined,
                                previewtoken: previewtoken,
                                sync_id: file.sync_id,
                                enc_data_key: enc_data_key,
                                enc_share_key: enc_share_key,
                                share_key: file.share_key || undefined,
                                share_key_id: curDataKey.share_key_id,
                                share_id: curDataKey.share_id,
                                share_sequence: curDataKey.share_sequence,
                                enc_name: file.enc_name,
                                status: transfer_model_1.TransferStatus.STATUS_WAITING,
                            }).catch(function (err) {
                                _this.loggerService.error('An error occurred creating download item');
                                throw err;
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    BuildTransferItemService.prototype.mkuploadItem = function (fileObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var itemStatus, cwd, filedate, tItem;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        itemStatus = 0;
                        if (!fileObj) {
                            this.loggerService.warn('An empty file object was passed to mkuploadItem');
                            itemStatus = transfer_model_1.TransferStatus.STATUS_ERR_NAME;
                        }
                        cwd = this.cwd ? this.cwd.sync_id : 0;
                        filedate = fileObj.lastModified || Date.now();
                        return [4 /*yield*/, this.transferItemService.get({
                                type: this.transferItemService.TYPE_UPLOAD,
                                sync_pid: cwd,
                                filename: fileObj.name.trim() || '',
                                filesize: fileObj.size || 0,
                                fileobj: fileObj,
                                status: itemStatus,
                                filedate: new Date(filedate).getTime(),
                            }).catch(function (err) { throw err; })];
                    case 1:
                        tItem = _a.sent();
                        if (!this.validateService.isNameValid(tItem.filename)) {
                            this.loggerService.warn('File name ' +
                                tItem.filename +
                                ' is not allowed to be uploaded');
                            tItem.status = transfer_model_1.TransferStatus.STATUS_ERR_NAME;
                        }
                        return [2 /*return*/, tItem];
                }
            });
        });
    };
    BuildTransferItemService.prototype.mkPublicUploadItem = function (fileObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tItem, cwd, encShareName;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mkuploadItem(fileObj).catch(function (err) { throw err; })];
                    case 1:
                        tItem = _a.sent();
                        cwd = this.linkCwd;
                        tItem.filename = this.linkFileListService.getValidFileName(tItem.filename);
                        tItem.share_id = cwd.share_id;
                        tItem.share_key = cwd.share_key;
                        tItem.share_sequence = cwd.share_sequence;
                        tItem.share_key_id = cwd.share_key_id;
                        tItem.sync_pid = cwd.sync_id;
                        tItem.linkID = cwd.linkID;
                        return [4 /*yield*/, this.linkFileListService.encName(tItem.filename).catch(function (err) { throw err; })];
                    case 2:
                        encShareName = _a.sent();
                        tItem.enc_share_name = encShareName;
                        return [2 /*return*/, tItem];
                }
            });
        });
    };
    BuildTransferItemService.prototype.mkCompatUrl = function (tItem, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var disp, mfsUrl, host, rsaDatakey, encHost, params, result, ts, input, defaults;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        disp = action == this.ACT_DOWNLOAD ? 'attachment' : 'inline';
                        host = window.location.href;
                        if (window.location.search) {
                            host = host.replace(window.location.search, '');
                        }
                        return [4 /*yield*/, this.syncCryptService.compatDatakeyEncrypt(this.syncCryptService.bytesToB64(tItem.data_key))];
                    case 1:
                        rsaDatakey = _a.sent();
                        return [4 /*yield*/, this.syncCryptService.compatDatakeyEncrypt(this.base64Service.encode(host))];
                    case 2:
                        encHost = _a.sent();
                        this.syncCookieService.deleteCookie('signature');
                        this.syncCookieService.deleteCookie('passwordlock');
                        tItem.disposition = disp;
                        if (!tItem.linkID) return [3 /*break*/, 7];
                        params = this.urlService.getDownloadPubLinkParams(tItem, rsaDatakey, encHost);
                        if (action == this.ACT_STREAM_DOC) {
                            params.preview = 'pdf';
                            params.action = 'stream';
                        }
                        return [4 /*yield*/, this.apiService.execute('linksignrequest', { 'req': params }).catch(function (err) { throw err; })];
                    case 3:
                        result = _a.sent();
                        params = result.response;
                        if (!tItem.linkpasswordlock) return [3 /*break*/, 4];
                        this.syncCookieService.setDownloadPubLink(tItem.linkpasswordlock);
                        mfsUrl = this.urlService.mkDownloadPubLinkPassword(tItem, params);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.urlService.mkDownloadPubLink(tItem, params)];
                    case 5:
                        mfsUrl = _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/, mfsUrl];
                    case 7:
                        ts = Date.now();
                        if (action == this.ACT_STREAM_DOC) {
                            mfsUrl = this.urlService.mkPreviewPubUser(tItem, rsaDatakey, ts);
                        }
                        else {
                            mfsUrl = this.urlService.mkDownloadPubUser(tItem, rsaDatakey, ts);
                        }
                        input = new models_1.BaseApiInput();
                        input.servtime = ts;
                        return [4 /*yield*/, this.syncCryptService.signApiReq(input).catch(function (err) { throw err; })];
                    case 8:
                        defaults = _a.sent();
                        mfsUrl = mfsUrl + '&access_token=' + defaults.access_token;
                        this.syncCookieService.setDownloadPubUser(defaults.signature);
                        return [2 /*return*/, mfsUrl];
                }
            });
        });
    };
    BuildTransferItemService.prototype.getStreamingDetails = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, mimeType, fileType, ext;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCompatUrl(item, this.ACT_PREVIEW)];
                    case 1:
                        url = _a.sent();
                        mimeType = '';
                        fileType = [
                            'mp4',
                            'm4v',
                            'm4p',
                            'ogg',
                            'mov',
                            'mkv',
                            'webm',
                            '3gp',
                            'ogv',
                            'mpg',
                            'mpeg',
                            'mp3',
                            'wav',
                            'aac',
                            'wmv',
                            'asf',
                            'flv',
                            'avi'
                        ];
                        ext = item.name
                            .substr(item.name.lastIndexOf('.') + 1)
                            .toLowerCase();
                        if (fileType.indexOf(ext) > -1) {
                            switch (ext) {
                                case 'mp4':
                                case 'm4v':
                                case 'm4p':
                                    mimeType = 'video/mp4';
                                    break;
                                case 'webm':
                                    mimeType = 'video/webm';
                                    break;
                                case 'mpg':
                                case 'mpeg':
                                    mimeType = 'video/mpeg';
                                    break;
                                case '3gp':
                                    mimeType = 'video/3gpp';
                                    break;
                                case 'ogv':
                                case 'ogg':
                                    mimeType = 'video/ogg';
                                    break;
                                case 'mov':
                                    mimeType = 'video/quicktime';
                                    break;
                                case 'mp3':
                                    mimeType = 'audio/mp3';
                                    break;
                                case 'wav':
                                    mimeType = 'audio/wav';
                                    break;
                                case 'aac':
                                    mimeType = 'audio/aac';
                                    break;
                                case 'wmv':
                                    mimeType = 'video/wmv';
                                    break;
                                case 'asf':
                                    mimeType = 'video/x-ms-asf';
                                    break;
                                case 'flv':
                                    mimeType = 'video/x-flv';
                                    break;
                                case 'avi':
                                    mimeType = 'video/avi';
                                    break;
                                case 'mkv':
                                    mimeType = 'video/x-matroska';
                                    break;
                                default:
                                    mimeType = null;
                                    break;
                            }
                            return [2 /*return*/, {
                                    url: url,
                                    mimeType: mimeType,
                                }];
                        }
                        else {
                            return [2 /*return*/, {
                                    url: null,
                                    mimeType: null,
                                }];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BuildTransferItemService.ngInjectableDef = i0.defineInjectable({ factory: function BuildTransferItemService_Factory() { return new BuildTransferItemService(i0.inject(i1.NotificationsService), i0.inject(i2.DownloadService), i0.inject(i3.FileListService), i0.inject(i4.BrowserSupportService), i0.inject(i5.SyncCookieService), i0.inject(i6.TransferItemService), i0.inject(i7.ValidateService), i0.inject(i8.Base64Service), i0.inject(i9.LoggerService), i0.inject(i10.SyncCryptService), i0.inject(i11.UrlService), i0.inject(i12.UserService), i0.inject(i13.ApiService), i0.inject(i14.LinkFileListService), i0.inject(i15.BroadcastService), i0.inject(i0.INJECTOR)); }, token: BuildTransferItemService, providedIn: "root" });
    return BuildTransferItemService;
}());
exports.BuildTransferItemService = BuildTransferItemService;
