import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LinkItem, ErrCode, LinkSendUrlApiInput } from '../../shared/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../core/user.service';
import { Base64Service } from '../../core/base64.service';
import { LoggerService } from '../../core/logger.service';
import { ApiService } from '../../core/api.service';
import { ValidateService } from '../../core/validate.service';
import { NotificationsService } from '../../core/notifications.service';
import { Subscription } from 'rxjs';
import { DirtyOutService } from '../../core/crypt/dirty-out.service';
import { FeatureService } from '../../shared/services/feature.service';

@Component({
    selector: 'sync-link-manage',
    templateUrl: './link-manage.component.html'
})
export class LinkManageComponent implements OnInit, OnDestroy {

    @Input() link: LinkItem;


    @Output() state = new EventEmitter<number>();
    @Output() close = new EventEmitter<boolean>();

    public shareForm: FormGroup;
    public isPro = 0;
    public linkSendResult = 0;
    public isOnTrial = 0;
    public isEnced = true;

    public spinner = false;
    public errcode: ErrCode;

    public copyText = 'Copy link';
    private subscription: Subscription;

    public total = 0;
    public finished = 0;
    public modalText: string;
    private isComponentActive = true;
    private isWsProgressAllowed = false;

    constructor(
        private api: ApiService,
        private base64: Base64Service,
        private fb: FormBuilder,
        private log: LoggerService,
        private userService: UserService,
        private validateService: ValidateService,
        private dirtyOut: DirtyOutService,
        private notificationsService: NotificationsService,
        public featureService: FeatureService
    ) { }

    async ngOnInit() {
        this.isPro = this.userService.get('is_pro');
        this.isOnTrial = this.userService.get('is_on_trial');
        this.shareForm = this.fb.group({
            email: ['', [Validators.email, Validators.required]],
            message: [''],
            displayname: [this.base64.decode(this.userService.get('display_name')), Validators.required]
        });

        this.isEnced = this.link.cnt > 0 ? false : true;
        this.isWsProgressAllowed = await this.featureService.isAllowed('websocketProgress');

        if (!this.isEnced) {
            this.encLink();
        }
    }

    public async encLink() {
        this.notificationsService.stopNotificationLoop();

        const updateModalText = (progress: number) => {
            this.modalText = this.isWsProgressAllowed
                ? `Encrypting link ${progress}%, Please wait...`
                : 'Encrypting link, Please wait...';
        };

        this.total = this.link.cnt + this.link.enc_cnt;
        this.finished = this.link.enc_cnt;
        updateModalText(Math.min(Math.round((this.finished / this.total) * 100), 100));

        while (this.finished < this.total && this.isComponentActive) {
            const data = await this.dirtyOut.executeForShare(this.link.share_id);

            if (!data || (Array.isArray(data) && data.length === 0)) {
                this.finished = this.total;
                updateModalText(100);
            }
            if (data && data.completed) {
                this.finished += data.completed;
                const percentage = Math.min(Math.round((this.finished / this.total) * 100), 100);
                updateModalText(percentage);

                if (this.finished >= this.total) {
                    updateModalText(100);
                    break;
                }
            }
        }
        this.modalText = 'Finalizing Link, Please wait...';
        this.isEnced = true;
        this.notificationsService.startNotificationLoop();
        this.log.info('post link action completed');
    }

    public copy(elemId) {
        const copyInput = <HTMLInputElement>document.getElementById(elemId);
        if (!copyInput) {
            this.log.warn('Could not find #' + elemId + ' ignoring');
            return;
        } else {
            this.copyText = 'Copied';
            copyInput.select();
            document.execCommand('copy');
            this.log.d('copied link url');
            window.setTimeout(() => {
                this.copyText = 'Copy link';
            }, 2000);
        }
    }

    public setState(state: number) {
        this.state.emit(state);
    }

    public closeDialog() {
        this.close.emit(false);
    }

    public async onSubmit() {
        const email = this.shareForm.get('email').value;
        const displayName = this.shareForm.get('displayname').value;

        try {
            this.linkSendResult = 0;
            this.spinner = true;

            if (!this.validateService.isDisplayNameValid(displayName)) {
                throw { code: 1659 };
            }

            this.errcode = null;
            const label = this.link.label || this.base64.encode('(No name provided)');
            await this.api.execute('linksendurl', {
                publink_id: this.link.cachekey,
                sync_id: this.link.sync_id,
                displayname: this.shareForm.get('displayname').value,
                linkname: label,
                linkurl: this.base64.encode(this.link.linkUrl),
                emailaddress: this.base64.encode(email),
                message: (this.userService.get('is_pro'))
                    ? this.base64.encode(this.shareForm.get('message').value)
                    : ''
            } as LinkSendUrlApiInput);
            this.linkSendResult = 1;
        } catch (ex) {
            this.spinner = false;
            this.errcode = ErrCode.fromException(ex);
            this.log.e('Error sending email to ' + email, ex);
        }
        this.spinner = false;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.notificationsService.startNotificationLoop();
        this.isComponentActive = false;
    }
}
