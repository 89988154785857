"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// This mapping file maps old CP route path to new CP route path.
exports.NewRoutesMapping = function (oldRoute) {
    var staticRoutes = {
        '/account/info': '/settings/profile',
        '/events': '/settings/activitylogs',
        '/account/devices': '/settings/devices',
        '/account/security': '/settings/security',
        '/account/billing': '/settings/billing',
        '/files': '/files',
        '/multi-user/users': '/users',
        '/multi-user/roles': '/users/roles'
    };
    // Check if the old route matches any of the static routes
    if (staticRoutes[oldRoute]) {
        return staticRoutes[oldRoute];
    }
    // Handle dynamic routes like /file/:id/view/:type
    // commented redirection code for previews for all new UI user. It will be uncommented once we have a feature check
    // const dynamicRoutePattern = /^\/file\/([^/]+)\/view\/([^/]+)$/;
    // const match = oldRoute.match(dynamicRoutePattern);
    // if (match) {
    //     const [_, id, type] = match;
    //     return `/file/${id}/view/${type}`;
    // }
    // Return null or handle unmatched routes
    return null;
};
