<div class="modal-header">
    <button
        type="button"
        class="close"
        id="close-manage-link"
        (click)="closeDialog()"
        aria-label="Close"
    >
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title ellipsis-text">
        <span>Manage link {{ link.name }}</span>
    </h4>
</div>
<div class="modal-body">
    <div class="error-general-overlay error-general-overlay-inline error-push-bottom" *ngIf="link.is_expired">
        <i class="fa fa-exclamation-triangle"></i>
        This link has expired and is no longer viewable.
    </div>
    
    <div class="error-general-overlay error-general-overlay-inline error-push-bottom" *ngIf="!this.isEnced">
        <i class="fa fa-spin fa-spinner"></i>
        {{ modalText }}
    </div>

    <div
        class="error-general-overlay error-general-overlay-inline error-push-bottom"
        role="alert"
        *ngIf="link.is_download_limit"
    >
        <i class="fa fa-exclamation-triangle"></i>
        This link has reached it's download limit and is no longer viewable.
    </div>

    <!-- link summary visible by default -->
    <div class="row" *ngIf="this.isEnced">
        <!-- VIEW 1 -->
        <div class="col-sm-12">
            <label class="" for="">Link:</label>
            <input
                type="text"
                class="form-control"
                [value]="link.linkUrl"
                readonly
                id="link-url"
            />

            <div class="link-settings-buttons">
                <a
                    class="btn btn-default btn-sm"
                    id="open-link-btn"
                    *ngIf="link.togglePassword"
                    [href]="link.linkUrl"
                    target="_blank"
                    >Open link</a
                >
                <button
                    type="button"
                    (click)="copy('link-url')"
                    class="btn btn-default btn-sm"
                    id="copy-link-btn"
                >
                    {{ copyText }}
                </button>
                <button
                    (click)="setState(2)"
                    type="button"
                    class="btn btn-primary btn-sm pull-right"
                    id="link-settings-path-btn"
                >
                    <i class="fas fa-cog hidden-xs"></i> Link settings
                </button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="link.compat && (isPro && !isOnTrial) && this.isEnced">
        <hr />

        <div class="row">
            <div class="col-sm-12">
                <div *ngIf="linkSendResult == 1" class="alert alert-info">
                    <i class="ion-checkmark-circled fa-lg syncblue"></i>
                    Successfully sent email
                </div>

                <sync-error [errcode]="errcode"></sync-error>

                <form
                    (submit)="shareForm.valid && onSubmit()"
                    [formGroup]="shareForm"
                    method="POST"
                    ngNativeValidate
                >
                    <label class="" for="">Send this link to:</label>
                    <input
                        autocomplete="off"
                        name="email"
                        type="email"
                        formControlName="email"
                        placeholder="Enter email address"
                        class="form-control"
                        id="input-email-share"
                    />
                    <textarea
                        *ngIf="isPro && !isOnTrial"
                        class="form-control"
                        name="message"
                        rows="3"
                        style="margin-top: 8px"
                        formControlName="message"
                        placeholder="Message"
                    ></textarea>

                    <div class="link-settings-buttons">
                        <button
                            [disabled]="!shareForm.valid || spinner"
                            class="btn btn-default btn-sm"
                            id="send-email-btn"
                            type="submit"
                        >
                            <i
                                [ngClass]="{
                                    'fas fa-envelope': !spinner,
                                    'fa fa-spin fa-spinner': spinner
                                }"
                            ></i>
                            Send email
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>
</div>
<!-- modal body  -->

<div class="modal-footer"></div>
