"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var api_service_1 = require("../../core/api.service");
var dirty_out_service_1 = require("../../core/crypt/dirty-out.service");
var link_password_service_1 = require("../services/link-password.service");
var base64_service_1 = require("../../core/base64.service");
var user_service_1 = require("../../core/user.service");
var logger_service_1 = require("../../core/logger.service");
var store_1 = require("@ngrx/store");
var FileListActions = require("../../actions/file-list.actions");
var censor_sensor_1 = require("censor-sensor");
var joblock_service_1 = require("../../core/joblock.service");
var censor = new censor_sensor_1.CensorSensor();
var websocket_service_1 = require("../../core/websocket.service");
var websocket_message_1 = require("../../shared/models/websocket/websocket-message");
var notifications_service_1 = require("../../core/notifications.service");
var feature_service_1 = require("../../shared/services/feature.service");
var LinkCreateComponent = /** @class */ (function () {
    function LinkCreateComponent(api, joblockService, base64, dirtyOut, linkPassword, log, store, userService, webSocketService, notificationsService, featureService) {
        this.api = api;
        this.joblockService = joblockService;
        this.base64 = base64;
        this.dirtyOut = dirtyOut;
        this.linkPassword = linkPassword;
        this.log = log;
        this.store = store;
        this.userService = userService;
        this.webSocketService = webSocketService;
        this.notificationsService = notificationsService;
        this.featureService = featureService;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.total = 0;
        this.finished = 0;
        this.linkLimitAmt = 0;
        this.bg = 1;
        this.isComponentActive = true;
        this.isWsProgressAllowed = false;
    }
    LinkCreateComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.item.is_publink) {
                            this.state.emit(1);
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.featureService.isAllowed('websocketProgress')];
                    case 1:
                        _a.isWsProgressAllowed = _b.sent();
                        this.verified = this.userService.getUser().is_verified == 1;
                        this.bg = 1;
                        if (!this.verified) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.makeLink()];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LinkCreateComponent.prototype.ngOnDestroy = function () {
        this.notificationsService.startNotificationLoop();
        this.isComponentActive = false;
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
    };
    LinkCreateComponent.prototype.postLinkCreateAction = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updateModalText, data, percentage;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.notificationsService.stopNotificationLoop();
                        updateModalText = function (progress) {
                            _this.modalText = _this.isWsProgressAllowed
                                ? "Encrypting link " + progress + "%, Please wait..."
                                : 'Encrypting link, Please wait...';
                        };
                        this.cachekey = this.bg === 1 ? this.newlinkjob.cachekey : this.newLink.cachekey;
                        this.log.info('cachekey filter status: ' + censor.isProfaneIsh(this.cachekey));
                        this.total = this.bg === 1 ? this.newlinkjob.cnt : this.newLink.cnt;
                        this.finished = 0;
                        _a.label = 1;
                    case 1:
                        if (!(this.finished < this.total && this.isComponentActive)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.dirtyOut.executeForShare(this.bg === 1 ? this.newlinkjob.share_id : this.newLink.share_id)];
                    case 2:
                        data = _a.sent();
                        if (!data || (Array.isArray(data) && data.length === 0)) {
                            this.finished = this.total;
                            updateModalText(100);
                        }
                        if (data && data.completed) {
                            this.finished += data.completed;
                            percentage = Math.min(Math.round((this.finished / this.total) * 100), 100);
                            updateModalText(percentage);
                            if (this.finished >= this.total) {
                                updateModalText(100);
                                return [3 /*break*/, 3];
                            }
                        }
                        return [3 /*break*/, 1];
                    case 3:
                        this.modalText = 'Finalizing Link, Please wait...';
                        this.store.dispatch(new FileListActions.FileListReloadAction());
                        this.state.emit(1);
                        this.spinner = false;
                        this.notificationsService.startNotificationLoop();
                        this.log.info('post link action completed');
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkCreateComponent.prototype.getLinkPassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pass;
            return tslib_1.__generator(this, function (_a) {
                do {
                    pass = this.linkPassword.makeLinkPassword(32);
                } while (censor.isProfaneIsh(pass));
                return [2 /*return*/, this.linkPassword.makePasswordData(pass)];
            });
        });
    };
    LinkCreateComponent.prototype.makeLink = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var passdata, linkParams, _a, response, _b, ex_1, limit;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.spinner = true;
                        this.progressSubscription = this.webSocketService
                            .getMessageHandler(websocket_message_1.MessageType.LINK_NEW_PROGRESS)
                            .subscribe(function (progress) {
                            _this.log.info('Received link creation progress update:');
                            _this.log.info(progress);
                            if (progress.sync_id === _this.item.sync_id) {
                                _this.createProgressPayload = progress;
                                _this.modalText = "Creating link " + _this.createProgressPayload.progressPercentage + "%, Please wait...";
                            }
                        });
                        this.modalText = 'Creating link, Please wait...';
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 10, , 11]);
                        return [4 /*yield*/, this.getLinkPassword()];
                    case 2:
                        passdata = _c.sent();
                        linkParams = {
                            sync_id: this.item.sync_id,
                            label: this.base64.encode(this.item.name),
                            enc_share_key: passdata.enc_share_key,
                            enc_password: passdata.enc_password,
                            salt: passdata.salt,
                            iterations: passdata.iterations,
                            bg: this.bg,
                        };
                        _c.label = 3;
                    case 3:
                        _a = this;
                        return [4 /*yield*/, this.api.execute('linknew', linkParams)];
                    case 4:
                        _a.newLink = _c.sent();
                        this.bg = this.newLink.bg;
                        if (!(this.bg == 1)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.joblockService.pollJobStatus()];
                    case 5:
                        response = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.api.execute('linkget', {
                                sync_id: this.item.sync_id,
                            })];
                    case 6:
                        _b.newlinkjob = _c.sent();
                        _c.label = 7;
                    case 7:
                        if (censor.isProfaneIsh(this.bg == 1 ? this.newlinkjob.cachekey : this.newLink.cachekey)) return [3 /*break*/, 3];
                        _c.label = 8;
                    case 8: return [4 /*yield*/, this.postLinkCreateAction()];
                    case 9:
                        _c.sent();
                        return [3 /*break*/, 11];
                    case 10:
                        ex_1 = _c.sent();
                        if (ex_1 instanceof models_1.ErrCode) {
                            if (ex_1.code == 8028) {
                                if (ex_1.msg) {
                                    this.log.warn('Link limit is reached ' + ex_1.msg);
                                    limit = ex_1.msg.toString().match(/\(([0-9]+)\)/);
                                    this.log.info(limit);
                                    if (limit !== null && parseInt(limit[1], 10) > 0) {
                                        this.linkLimitAmt = parseInt(limit[1], 10);
                                    }
                                    else {
                                        this.log.error('Could not determine the link limit from the API, hardcoding to a limit of 3.  Received : "' +
                                            ex_1.msg +
                                            '"');
                                        this.linkLimitAmt = 3;
                                    }
                                }
                                this.isLinkLimit = true;
                            }
                            else {
                                this.errcode = ex_1;
                            }
                        }
                        else {
                            this.errcode = new models_1.ErrCode(1000, 'An error occurred creating the link');
                            this.log.error('An error occurred creating the link');
                            this.log.error(ex_1);
                        }
                        this.spinner = false;
                        return [3 /*break*/, 11];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    return LinkCreateComponent;
}());
exports.LinkCreateComponent = LinkCreateComponent;
