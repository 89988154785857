"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var environment_1 = require("../environments/environment");
var blend_service_1 = require("./shared/services/blend.service");
var router_1 = require("@angular/router");
var new_route_mapping_1 = require("./shared/models/new-route-mapping");
var user_service_1 = require("./core/user.service");
var unauthblend_service_1 = require("./shared/services/unauthblend.service");
var feature_service_1 = require("./shared/services/feature.service");
function windowFactory() {
    return window;
}
exports.windowFactory = windowFactory;
var AppModule = /** @class */ (function () {
    function AppModule(router, blendService, userService, featureService, unauthBlendService) {
        var _this = this;
        this.router = router;
        this.blendService = blendService;
        this.userService = userService;
        this.featureService = featureService;
        this.unauthBlendService = unauthBlendService;
        this.router.events.subscribe(function (routeEvent) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var newRoute;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(routeEvent instanceof router_1.NavigationStart)) return [3 /*break*/, 5];
                        newRoute = new_route_mapping_1.NewRoutesMapping(routeEvent.url);
                        if (!routeEvent.url.includes('/files')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.featureService.isAllowed('filesDirectoryPage')];
                    case 1:
                        if (_a.sent()) {
                            newRoute = routeEvent.url;
                        }
                        else {
                            newRoute = '';
                        }
                        _a.label = 2;
                    case 2:
                        if (!routeEvent.url.includes('/users')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.featureService.isAllowed('newTeamsUsersPage')];
                    case 3:
                        if (_a.sent()) {
                            newRoute = routeEvent.url;
                        }
                        else {
                            newRoute = '';
                        }
                        _a.label = 4;
                    case 4:
                        if (this.userService.get('is_newui_enabled') && newRoute) {
                            window.location.href = environment_1.environment.syncCpHost + newRoute;
                        }
                        _a.label = 5;
                    case 5:
                        if (routeEvent instanceof router_1.NavigationEnd) {
                            window.scrollTo(0, 0);
                            if (!this.userService.isAuthenticated()) {
                                this.unauthBlendService.trackPageview();
                            }
                            else {
                                this.blendService.trackPageview();
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    }
    return AppModule;
}());
exports.AppModule = AppModule;
