// WebSocketMessage.ts
import { Deserializable } from '../deserializable.model';
import { ActionPayload } from './action-payload';

export enum MessageType {
    MOVE_PROGRESS = 'move_progress',
    SHARE_PROGRESS = 'share_progress',
    UPDATE_USER = 'update_user',
    LINK_NEW_PROGRESS = 'link_new_progress',
    // Add other actions as needed
}


export class WebSocketMessage implements Deserializable<WebSocketMessage> {
    type: MessageType;           // Specific action being performed (e.g., move_progress, share_progress, update_user, update_pathlist)
    user_id: number;          // User ID associated with the action
    timestamp: Date;         // Timestamp of the action
    payload: ActionPayload;   // Specific payload type

    constructor(params?: { [k: string]: any }) {
        if (params) {
            this.deserialize(params);
        }
    }

    public deserialize(params: any) {
        Object.assign(this, params);
        return this;
    }
}
