"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MessageType;
(function (MessageType) {
    MessageType["MOVE_PROGRESS"] = "move_progress";
    MessageType["SHARE_PROGRESS"] = "share_progress";
    MessageType["UPDATE_USER"] = "update_user";
    MessageType["LINK_NEW_PROGRESS"] = "link_new_progress";
    // Add other actions as needed
})(MessageType = exports.MessageType || (exports.MessageType = {}));
var WebSocketMessage = /** @class */ (function () {
    function WebSocketMessage(params) {
        if (params) {
            this.deserialize(params);
        }
    }
    WebSocketMessage.prototype.deserialize = function (params) {
        Object.assign(this, params);
        return this;
    };
    return WebSocketMessage;
}());
exports.WebSocketMessage = WebSocketMessage;
