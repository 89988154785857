"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../link-create/link-create.component.ngfactory");
var i2 = require("../link-create/link-create.component");
var i3 = require("../../core/api.service");
var i4 = require("../../core/joblock.service");
var i5 = require("../../core/base64.service");
var i6 = require("../../core/crypt/dirty-out.service");
var i7 = require("../services/link-password.service");
var i8 = require("../../core/logger.service");
var i9 = require("@ngrx/store");
var i10 = require("../../core/user.service");
var i11 = require("../../core/websocket.service");
var i12 = require("../../core/notifications.service");
var i13 = require("../../shared/services/feature.service");
var i14 = require("../link-manage/link-manage.component.ngfactory");
var i15 = require("../link-manage/link-manage.component");
var i16 = require("@angular/forms");
var i17 = require("../../core/validate.service");
var i18 = require("../link-settings/link-settings.component.ngfactory");
var i19 = require("../link-settings/link-settings.component");
var i20 = require("@angular/router");
var i21 = require("../../core/crypt/sync-crypt.service");
var i22 = require("../../link-consume/services/link-file-list.service");
var i23 = require("../../shared/services/blend.service");
var i24 = require("../../file/file-item.service");
var i25 = require("../link-delete/link-delete.component.ngfactory");
var i26 = require("../link-delete/link-delete.component");
var i27 = require("../link-suspended/link-suspended.component.ngfactory");
var i28 = require("../link-suspended/link-suspended.component");
var i29 = require("@ng-bootstrap/ng-bootstrap");
var i30 = require("@angular/common");
var i31 = require("./dialog-link.component");
var i32 = require("../services/link-list.service");
var styles_DialogLinkComponent = [];
var RenderType_DialogLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogLinkComponent, data: {} });
exports.RenderType_DialogLinkComponent = RenderType_DialogLinkComponent;
function View_DialogLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-link-create", [], null, [[null, "state"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("state" === en)) {
        var pd_0 = (_co.onStateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_LinkCreateComponent_0, i1.RenderType_LinkCreateComponent)), i0.ɵdid(1, 245760, null, 0, i2.LinkCreateComponent, [i3.ApiService, i4.JoblockService, i5.Base64Service, i6.DirtyOutService, i7.LinkPasswordService, i8.LoggerService, i9.Store, i10.UserService, i11.WebSocketService, i12.NotificationsService, i13.FeatureService], { openState: [0, "openState"], item: [1, "item"] }, { state: "state", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openState; var currVal_1 = _co.item; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DialogLinkComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-link-manage", [], null, [[null, "state"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("state" === en)) {
        var pd_0 = (_co.onStateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_LinkManageComponent_0, i14.RenderType_LinkManageComponent)), i0.ɵdid(1, 245760, null, 0, i15.LinkManageComponent, [i3.ApiService, i5.Base64Service, i16.FormBuilder, i8.LoggerService, i10.UserService, i17.ValidateService, i6.DirtyOutService, i12.NotificationsService, i13.FeatureService], { link: [0, "link"] }, { state: "state", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DialogLinkComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-link-settings", [], null, [[null, "state"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("state" === en)) {
        var pd_0 = (_co.onStateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i18.View_LinkSettingsComponent_0, i18.RenderType_LinkSettingsComponent)), i0.ɵdid(1, 114688, null, 0, i19.LinkSettingsComponent, [i3.ApiService, i5.Base64Service, i16.FormBuilder, i8.LoggerService, i9.Store, i10.UserService, i20.Router, i21.SyncCryptService, i22.LinkFileListService, i23.BlendService, i24.FileItemService], { link: [0, "link"], item: [1, "item"] }, { state: "state", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; var currVal_1 = _co.item; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DialogLinkComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-link-delete", [], null, [[null, "state"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("state" === en)) {
        var pd_0 = (_co.onStateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i25.View_LinkDeleteComponent_0, i25.RenderType_LinkDeleteComponent)), i0.ɵdid(1, 114688, null, 0, i26.LinkDeleteComponent, [i3.ApiService, i8.LoggerService, i9.Store], { link: [0, "link"] }, { state: "state", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DialogLinkComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-link-suspended", [], null, [[null, "state"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("state" === en)) {
        var pd_0 = (_co.onStateChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClose($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i27.View_LinkSuspendedComponent_0, i27.RenderType_LinkSuspendedComponent)), i0.ɵdid(1, 114688, null, 0, i28.LinkSuspendedComponent, [i29.NgbActiveModal], { link: [0, "link"] }, { state: "state", close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DialogLinkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogLinkComponent_1)), i0.ɵdid(1, 16384, null, 0, i30.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogLinkComponent_2)), i0.ɵdid(3, 16384, null, 0, i30.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogLinkComponent_3)), i0.ɵdid(5, 16384, null, 0, i30.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogLinkComponent_4)), i0.ɵdid(7, 16384, null, 0, i30.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogLinkComponent_5)), i0.ɵdid(9, 16384, null, 0, i30.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.openState == 0) && _co.initialized); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.openState == 1) && _co.initialized); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.openState == 2) && _co.initialized); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.openState == 3) && _co.initialized); _ck(_v, 7, 0, currVal_3); var currVal_4 = ((_co.openState == 10) && _co.initialized); _ck(_v, 9, 0, currVal_4); }, null); }
exports.View_DialogLinkComponent_0 = View_DialogLinkComponent_0;
function View_DialogLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sync-dialog-link", [], null, null, null, View_DialogLinkComponent_0, RenderType_DialogLinkComponent)), i0.ɵdid(1, 114688, null, 0, i31.DialogLinkComponent, [i29.NgbActiveModal, i32.LinkListService, i10.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DialogLinkComponent_Host_0 = View_DialogLinkComponent_Host_0;
var DialogLinkComponentNgFactory = i0.ɵccf("sync-dialog-link", i31.DialogLinkComponent, View_DialogLinkComponent_Host_0, { item: "item", pid: "pid", openState: "openState" }, {}, []);
exports.DialogLinkComponentNgFactory = DialogLinkComponentNgFactory;
