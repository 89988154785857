"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var models_1 = require("../../shared/models");
var forms_1 = require("@angular/forms");
var user_service_1 = require("../../core/user.service");
var base64_service_1 = require("../../core/base64.service");
var logger_service_1 = require("../../core/logger.service");
var api_service_1 = require("../../core/api.service");
var validate_service_1 = require("../../core/validate.service");
var notifications_service_1 = require("../../core/notifications.service");
var dirty_out_service_1 = require("../../core/crypt/dirty-out.service");
var feature_service_1 = require("../../shared/services/feature.service");
var LinkManageComponent = /** @class */ (function () {
    function LinkManageComponent(api, base64, fb, log, userService, validateService, dirtyOut, notificationsService, featureService) {
        this.api = api;
        this.base64 = base64;
        this.fb = fb;
        this.log = log;
        this.userService = userService;
        this.validateService = validateService;
        this.dirtyOut = dirtyOut;
        this.notificationsService = notificationsService;
        this.featureService = featureService;
        this.state = new core_1.EventEmitter();
        this.close = new core_1.EventEmitter();
        this.isPro = 0;
        this.linkSendResult = 0;
        this.isOnTrial = 0;
        this.isEnced = true;
        this.spinner = false;
        this.copyText = 'Copy link';
        this.total = 0;
        this.finished = 0;
        this.isComponentActive = true;
        this.isWsProgressAllowed = false;
    }
    LinkManageComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isPro = this.userService.get('is_pro');
                        this.isOnTrial = this.userService.get('is_on_trial');
                        this.shareForm = this.fb.group({
                            email: ['', [forms_1.Validators.email, forms_1.Validators.required]],
                            message: [''],
                            displayname: [this.base64.decode(this.userService.get('display_name')), forms_1.Validators.required]
                        });
                        this.isEnced = this.link.cnt > 0 ? false : true;
                        _a = this;
                        return [4 /*yield*/, this.featureService.isAllowed('websocketProgress')];
                    case 1:
                        _a.isWsProgressAllowed = _b.sent();
                        if (!this.isEnced) {
                            this.encLink();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkManageComponent.prototype.encLink = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updateModalText, data, percentage;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.notificationsService.stopNotificationLoop();
                        updateModalText = function (progress) {
                            _this.modalText = _this.isWsProgressAllowed
                                ? "Encrypting link " + progress + "%, Please wait..."
                                : 'Encrypting link, Please wait...';
                        };
                        this.total = this.link.cnt + this.link.enc_cnt;
                        this.finished = this.link.enc_cnt;
                        updateModalText(Math.min(Math.round((this.finished / this.total) * 100), 100));
                        _a.label = 1;
                    case 1:
                        if (!(this.finished < this.total && this.isComponentActive)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.dirtyOut.executeForShare(this.link.share_id)];
                    case 2:
                        data = _a.sent();
                        if (!data || (Array.isArray(data) && data.length === 0)) {
                            this.finished = this.total;
                            updateModalText(100);
                        }
                        if (data && data.completed) {
                            this.finished += data.completed;
                            percentage = Math.min(Math.round((this.finished / this.total) * 100), 100);
                            updateModalText(percentage);
                            if (this.finished >= this.total) {
                                updateModalText(100);
                                return [3 /*break*/, 3];
                            }
                        }
                        return [3 /*break*/, 1];
                    case 3:
                        this.modalText = 'Finalizing Link, Please wait...';
                        this.isEnced = true;
                        this.notificationsService.startNotificationLoop();
                        this.log.info('post link action completed');
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkManageComponent.prototype.copy = function (elemId) {
        var _this = this;
        var copyInput = document.getElementById(elemId);
        if (!copyInput) {
            this.log.warn('Could not find #' + elemId + ' ignoring');
            return;
        }
        else {
            this.copyText = 'Copied';
            copyInput.select();
            document.execCommand('copy');
            this.log.d('copied link url');
            window.setTimeout(function () {
                _this.copyText = 'Copy link';
            }, 2000);
        }
    };
    LinkManageComponent.prototype.setState = function (state) {
        this.state.emit(state);
    };
    LinkManageComponent.prototype.closeDialog = function () {
        this.close.emit(false);
    };
    LinkManageComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var email, displayName, label, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        email = this.shareForm.get('email').value;
                        displayName = this.shareForm.get('displayname').value;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.linkSendResult = 0;
                        this.spinner = true;
                        if (!this.validateService.isDisplayNameValid(displayName)) {
                            throw { code: 1659 };
                        }
                        this.errcode = null;
                        label = this.link.label || this.base64.encode('(No name provided)');
                        return [4 /*yield*/, this.api.execute('linksendurl', {
                                publink_id: this.link.cachekey,
                                sync_id: this.link.sync_id,
                                displayname: this.shareForm.get('displayname').value,
                                linkname: label,
                                linkurl: this.base64.encode(this.link.linkUrl),
                                emailaddress: this.base64.encode(email),
                                message: (this.userService.get('is_pro'))
                                    ? this.base64.encode(this.shareForm.get('message').value)
                                    : ''
                            })];
                    case 2:
                        _a.sent();
                        this.linkSendResult = 1;
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        this.spinner = false;
                        this.errcode = models_1.ErrCode.fromException(ex_1);
                        this.log.e('Error sending email to ' + email, ex_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.spinner = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LinkManageComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.notificationsService.startNotificationLoop();
        this.isComponentActive = false;
    };
    return LinkManageComponent;
}());
exports.LinkManageComponent = LinkManageComponent;
